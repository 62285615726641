import Axios from "axios"
import { navigate } from "gatsby"

import { getAdminAccessToken } from "./adminAuth"

// const showLoader = () => {
//   if (document.getElementsByClassName("leftNavSent").length > 0) {
//     document.getElementsByClassName("leftNavSent")[0].classList.remove("d-none")
//   }
// }

// const hideLoader = () => {
//   if (document.getElementsByClassName("leftNavSent").length > 0) {
//     document.getElementsByClassName("leftNavSent")[0].classList.add("d-none")
//   }
// }

const AdminAxiosInstance = Axios.create({
  baseURL: process.env.GATSBY_BACKEND_API_URL + "/admin",
})

AdminAxiosInstance.interceptors.request.use(
  config => {
    // showLoader()

    const token = getAdminAccessToken()
    if (token) {
      config.headers["Authorization"] = ` Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

const axiosInterceptor = AdminAxiosInstance.interceptors.response.use(
  res => {
    // hideLoader()

    return res
  },
  async err => {
    // hideLoader()

    const originalConfig = err.config

    if (err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true

        Axios.interceptors.response.eject(axiosInterceptor)

        if (err.config.url.includes("/token")) {
          window.localStorage.setItem(
            "securityMsg",
            "For security reasons you are logged out, Please login again!"
          )
          navigate("/superAdmin0rama/login")
        }

        try {
          const rs = await getRefreshTokenRequest()

          const { access_token, refresh_token } = rs
          window.localStorage.setItem("adminJwt", access_token)
          window.localStorage.setItem("adminRefreshJwt", refresh_token)

          return AdminAxiosInstance({
            ...originalConfig,
            headers: { ...originalConfig.headers, Authorization: `Bearer ${access_token}` },
            sent: true,
          })
        } catch (_error) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data)
          }

          return Promise.reject(_error)
        }
      }
    }

    return Promise.reject(err)
  }
)

let refreshTokenRequest = null
const resetAuthTokenRequest = () => {
  refreshTokenRequest = null
}

const getRefreshTokenRequest = () => {
  if (!refreshTokenRequest) {
    refreshTokenRequest = refreshToken()
    refreshTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest)
  }

  return refreshTokenRequest
}

const refreshToken = async () => {
  try {
    let formData = new FormData()
    formData.append("grant_type", process.env.GATSBY_USER_GRANT_TYPE_TOKEN)
    formData.append("scope", process.env.GATSBY_ADMIN_SCOPE)
    formData.append("client_id", process.env.GATSBY_ADMIN_CLIENT_ID)
    formData.append("client_secret", process.env.GATSBY_ADMIN_CLIENT_SECRET)
    formData.append("refresh_token", window.localStorage.getItem("adminRefreshJwt"))

    const resp = await AdminAxiosInstance.post(`${process.env.GATSBY_API_URL}/token`, formData)

    return resp.data
  } catch (e) {
    console.log("Error", e)
  }
}

export default AdminAxiosInstance
