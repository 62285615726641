import React, { useState } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import DetailChatBoxPopup from "../Detail/detailChatBoxPopup"
import Moment from "react-moment"

const UserList = ({ data }) => {
  const [selectFile, setSelectFile] = useState(false),
    [chatBox, setChatBox] = useState(false)

  return (
    <div className="table-responsive">
      <table id="UsersList" className="table dataTable" cellSpacing="0" width="100%">
        <thead>
          <tr>
            <th className="ps-4" width="6%">
              <a href="" className="btnTableAction">
                <i className="bi bi-gear-fill"></i>
              </a>
            </th>
            <th className="text-center" width="8%">
              ID
            </th>
            {/* <th className="text-center d-md-table-cell" width="6%">
                        Rave Score
                      </th> */}
            <th width="28%">Username</th>
            <th className="d-sm-table-cell" width="28%">
              Email
            </th>
            {/* <th className="text-center d-md-table-cell" width="8%">
							Score
						</th> */}
            <th className="d-md-table-cell" width="8%">
              Date
            </th>
            <th width="7%" className="text-end d-md-table-cell"></th>
          </tr>
          {selectFile === true && (
            <tr className="table_action_container">
              <td colspan="8" className="text-left">
                <div className="batch-action-container">
                  <button className="btn btn-trash">
                    <i className="bi bi-trash-fill"></i> Move to Trash
                  </button>{" "}
                  <button className="btn btn-secondary">
                    <i className="bi bi-folder-fill"></i> Move To Folder
                  </button>
                </div>
              </td>
            </tr>
          )}
        </thead>
        <tbody>
          {data &&
            data.length > 0 &&
            data.map((users, index) => {
              return (
                <tr>
                  <td className="text-center tableColChkDel" key={index}>
                    <div className="tableColChkDelBox">
                      <input
                        type="checkbox"
                        name="fileid[]"
                        id="C_ID1"
                        className="css-checkbox"
                        onChange={e => setSelectFile(e.target.checked)}
                      />
                      <label htmlFor="C_ID1" className="css-checkbox-label"></label>
                    </div>
                  </td>
                  <td className="text-center">
                    {users.id}
                    <br />
                    {/* <button className="btn btn-success btn-sm">
											<i className="bi bi-box-arrow-in-right"></i> 12345
										</button> */}
                  </td>
                  {/* <td className="text-center d-none d-md-table-cell">
										<span className="user_rave_score_badge">40</span>
									</td> */}
                  <td>
                    <a href="user_details.html">
                      <i className="bi bi-person-fill"></i> {users.username}
                    </a>{" "}
                    {/* <div className="SwiftCloudTableTags ms-2">
                          <ul>
                            <li>
                              <a href="javaScript:void(0);" data-tagname="Tag1">
                                Tag1
                              </a>
                            </li>
                            <li>
                              <a href="javaScript:void(0);" data-tagname="Tag2">
                                Tag2
                              </a>
                            </li>
                          </ul>
                        </div> */}
                  </td>
                  <td className="d-sm-table-cell">
                    <a
                      href="#"
                      className="btnOpenChatDockEmail"
                      data-type="Email"
                      data-value="teamcbcnc@gmail.com"
                    >
                      {users.email}
                    </a>
                  </td>
                  {/* <td className="text-center d-none d-md-table-cell">1351</td> */}
                  <td className="d-none d-md-table-cell">
                    {users.lastLoggedIn === null ? (
                      ""
                    ) : (
                      <Moment format="YYYY/MM/DD">{users.lastLoggedIn}</Moment>
                    )}
                  </td>
                  <td className="text-end tableColAction d-none d-md-table-cell">
                    {/* <OverlayTrigger placement="top" overlay={<Tooltip>Chat</Tooltip>}>
                            <a
                              className="btn btn-secondary btnChatDock"
                              onClick={()=>setChatBox(true)}
                            >
                              <i className="bi bi-chat-fill"></i>
                            </a>
                          </OverlayTrigger>{" "} */}
                    <OverlayTrigger placement="left" overlay={<Tooltip>Login</Tooltip>}>
                      <a
                        href={`/login?switch_user=${users.email}`}
                        className="btn btn-sm btn-primary"
                        target="_blank"
                      >
                        <i className="bi bi-box-arrow-in-right" />
                      </a>
                    </OverlayTrigger>{" "}
                    <OverlayTrigger
                      placement="left"
                      overlay={<Tooltip>Hide / Do Not Count / Suppress / Internal</Tooltip>}
                    >
                      <a href="#" className="btn btn-delete">
                        <i className="bi bi-eye-slash-fill" />
                      </a>
                    </OverlayTrigger>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
      {chatBox === true && <DetailChatBoxPopup setChatBox={setChatBox} />}
    </div>
  )
}

export default UserList
